.signup-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 24px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.brand-logo img.logo {
  display: block;
  margin: 0 auto 24px;
  width: 180px;
}

.offer-info {
  text-align: center;
  margin-bottom: 24px;
}

.offer-info h2 {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 500;
  color: #333;
}

.pricing-amount {
  font-size: 20px;
  font-weight: 400;
  color: #5C67F2;
  margin-bottom: 16px;
}

.pricing-features {
  list-style-type: none;
  padding: 0;
  margin: 0 0 16px;
  font-size: 16px;
  color: #555;
}

.pricing-features li {
  margin-bottom: 8px;
}

.trial-info {
  font-size: 18px; /* Increased font size for better visibility */
  color: #5C67F2; /* Changed color to match the primary theme color */
  font-weight: 600; /* Added boldness to make it stand out */
  margin-top: 16px; /* Added some space above for better separation */
  text-align: center; /* Centered the text for better alignment */
}


.google-btn {
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #4285F4;
  border: 2px solid #4285F4;
  border-radius: 8px;
  margin-bottom: 24px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.google-btn:hover {
  background-color: #f0f0f0;
}

.google-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.or-separator {
  text-align: center;
  margin: 24px 0;
  font-size: 14px;
  color: #666;
}

.signup-form input.form-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
}

.signup-form .submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #5C67F2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.signup-form .submit-btn:hover {
  background-color: #4e56cc;
}

.link-container {
  text-align: center;
  margin-top: 24px;
}

.simple-link {
  color: #4285F4;
  text-decoration: none;
  font-size: 14px;
}

.simple-link:hover {
  text-decoration: underline;
}
