.create-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.input-title, .textarea-notes {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-add-slice {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.button-add-slice:disabled {
  background-color: #ccc;
}

.draggable-slice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-top: 10px;
  background-color: #f8f9fa; /* Updated to lighter gray */
  border: 1px solid #dee2e6; /* Subtle light border */
  border-radius: 4px;
}

.slice-name {
  flex-grow: 1;
  margin-right: 10px;
}

.button-group {
  display: flex;
  gap: 8px;
}

.button-edit-slice, .button-remove-slice {
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.button-edit-slice {
  background-color: #007bff;
}

.button-remove-slice {
  background-color: white;
  color: #dc3545; /* Red text for a distinct look */
  border: 2px solid #dc3545; /* Red outline */
  cursor: pointer;
}

.button-done {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-done:hover {
  background-color: #0056b3;
}
